export default {
    API_URL: "https://api.renvel.com.br/api/",
    TIMEOUT_REQUEST: 50000,
    ACCESS_TOKEN: "BHN44Fv7dAZ856X3GCsqHatfSLmt7Cj2oWZheUBfeBkh4Ii62d7e2522037c",
    TOKEN_AUTHORIZATION: "!g*dfPiVOXoA@a1wfJeUDg&800HDRiZ2#$c7LAz*A7#dH9vfdD",
    HEADER_REQUEST: {
        Accept: 'application/json',
        Authorization: 'Bearer BHN44Fv7dAZ856X3GCsqHatfSLmt7Cj2oWZheUBfeBkh4Ii62d7e2522037c'
        
    }
}