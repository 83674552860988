import axios from "axios";
import config from "../config/config";

const API_URL =  config.API_URL;

export const login = (username: string, password: string) => {
  
  const data = {
    username: username,
    password: password
  }

  return axios({
    url: API_URL + "users/auth/login",
    method: "POST",
    timeout: config.TIMEOUT_REQUEST,
    data: data,
    headers: config.HEADER_REQUEST
    })
    .then((response) => {
      console.log('response', response)
      // if (config.ACCESS_TOKEN) {
      //   localStorage.setItem("user", JSON.stringify(response.data));
      // }
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);

  return null;
};
