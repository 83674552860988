import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import config from "../config/config";
import { getCurrentUser } from "../services/auth.service";
import * as Yup from "yup";

const Profile: React.FC = () => {
 
  const currentUser = getCurrentUser();
  const API_URL = config.API_URL;
  const token = currentUser.accessToken;

  const headers = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  };

  const initialValues: {
    id: string;
    ip: string;
  } = {
    id: "",
    ip: "",
  };

  const validationSchema = Yup.object().shape({
    id: Yup.string().required("Esse campo é obrigatório!"),
    ip: Yup.string().required("Esse campo é obrigatório!"),
  });
  
  const [ repo, setRepo] = useState<any[]>([]);
  const [ msg, setMsg] = useState('');

  const getRepo = () => {
    axios.get(API_URL + "users", headers)
    .then((response) => {
      const myRepo = response.data;
      setRepo(myRepo);
    })
  }
 
  useEffect(() => getRepo(), []);

  const handleIp = (formValue: { id: string; ip: string }) => {
    const { id, ip } = formValue;
  
    axios.patch(`${API_URL}users/${id}`,  { ip: ip }, headers)
    .then((response) => {      
      window.location.reload();
      setMsg(`${response.data}`);
    })

    

  };

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>{currentUser.username}</strong> Liberação de IPs
        </h3>
      </header>
      <p>
        <strong>Token:</strong> {currentUser.accessToken.substring(0, 20)} ...{" "}
        {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
      </p>
      <p>
        <strong>Id:</strong> {currentUser.id}
      </p>
      <p>
        <strong>Email:</strong> {currentUser.email}
      </p>   
      <hr/>
      <h3>
         Usuários
        
      </h3>   
        {msg && 
         <div className="alert alert-success mt-2">
         {msg}
        </div>  
        }
        
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Nome</th>
            <th scope="col">E-mail</th>
            <th scope="col">IP</th>
          </tr>
        </thead>
        <tbody>
           {repo.map((item) => (
            <tr key={item.id}>
            <th scope="row">{item.id}</th>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.ip}</td>
          </tr>
          ))}
        </tbody>
      </table>
      <hr/>

      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleIp}
        >
          <Form>
            <div className="form-group">
              <div className="row">
                  <div className="col-3">
                    <label htmlFor="id">ID</label>
                      <Field name="id" type="text" className="form-control" />
                      <ErrorMessage
                        name="id"
                        component="div"
                        className="alert alert-danger mt-2"
                      />
                  </div>
                  <div className="col-3">
                    <label htmlFor="ip">IP</label>
                    <Field name="ip" type="text" className="form-control" />
                    <ErrorMessage
                      name="ip"
                      component="div"
                      className="alert alert-danger mt-2"
                    />
                  </div>
                  <div className="col-3 pt-3">
                    <button type="submit" className="btn btn-success btn-block mt-3">               
                      <span>CADASTRAR</span>
                    </button>
                  </div>
              </div>             
            </div>          
          </Form>
        </Formik>
         
    </div>
  );
};

export default Profile;
